import { SagaIterator } from 'redux-saga';
import { call, take, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { addiDataRequestDetailsSlice } from '../slices';

import { getAddiDataRequestDetailsSaga } from './getAddiDataRequestDetailsSaga';

export function* getAddiDataRequestDetailsFlowSaga(): SagaIterator {

	console.log('in getAddiDataRequestDetailsFlowSaga');

	const { payload } = (yield take(addiDataRequestDetailsSlice.actions.getAddiDataRequest.type)) as PayloadAction<{ id: string }>;

	console.log(payload);

	yield all([ call(getAddiDataRequestDetailsSaga, payload.id) ]);
}
