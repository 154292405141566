import React, { FunctionComponent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { ReactComponent as CloseDrawerIcon } from 'assets/icons/RightArrowSquaredIcon.svg';
import { ReactComponent as OpenDrawerIcon } from 'assets/icons/LeftArrowSquaredIcon.svg';

import { FILTERS_DRAWER_OPENED_WIDTH_PX, FILTERS_DRAWER_CLOSED_WIDTH_PX } from '../constants';
import { selectFilters } from '../selectors';

import { FiltersPanelGroup } from './FiltersPanelGroup';

const useStyles = makeStyles((theme: Theme) => ({
	drawer: {
		flexShrink: 0,
		whiteSpace: 'nowrap',
		height: '100%',
		width: FILTERS_DRAWER_OPENED_WIDTH_PX
	},
	drawerPaper: {
		position: 'relative',
		height: '100%',
		backgroundColor: grey['50'],
		zIndex: 1,
		overflowX: 'hidden'
	},
	listIcon: {
		minWidth: '40px'
	},

	drawerOpen: {
		width: FILTERS_DRAWER_OPENED_WIDTH_PX,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		width: FILTERS_DRAWER_CLOSED_WIDTH_PX,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		})
	},
	closedDrawerText: {
		textTransform: 'uppercase',
		transform: 'rotate(90deg)',
		position: 'absolute',
		left: '-22px',
		top: '95px'
	},
}));

export const FiltersPanel: FunctionComponent = () => {
	const classes = useStyles();

	const filters = useSelector(selectFilters);

	const [isDrawerOpen, setIsDrawerOpen] = useState(true);

	const handleToggleDrawer = useCallback(() => {
		setIsDrawerOpen(prevState => !prevState);
	}, []);

	return (
		<Drawer
			variant="permanent"
			anchor="right"
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: isDrawerOpen,
				[classes.drawerClose]: !isDrawerOpen,
			})}
			classes={{
				paper: clsx(classes.drawerPaper, {
					[classes.drawerOpen]: isDrawerOpen,
					[classes.drawerClose]: !isDrawerOpen,
				})
			}}
		>
			<Box pt={1} display="flex" justifyContent="space-between" alignItems="center" position="absolute" left={0} width={ FILTERS_DRAWER_OPENED_WIDTH_PX }>
				<Box px={0.75}>
					<IconButton
						aria-label={ isDrawerOpen ? 'Collapse filters panel' : 'Expand filters panel' }
						onClick={handleToggleDrawer}
					>
						{
							isDrawerOpen
								? <CloseDrawerIcon />
								: <OpenDrawerIcon />
						}
					</IconButton>
				</Box>
				<Box pr={2}>
					<Typography component='h2' variant='h5'>
						{ 'Filters' }
					</Typography>
				</Box>
			</Box>
			<Box pb={7.5} />
			{isDrawerOpen ?
				<>
					<Divider />
					<Box width={ FILTERS_DRAWER_OPENED_WIDTH_PX }>
						{filters.map(filter =>
							<FiltersPanelGroup key={filter.name} filter={filter} />
						)}
					</Box>
				</> :
				<Typography component='span' className={classes.closedDrawerText} variant='subtitle2'>
					{ 'Show Filters' }
				</Typography>
			}
		</Drawer>
	);
};
