import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { handleErrorSaga } from 'features/errorHandling/sagas';

import { addiDataRequestDetailsSlice } from '../slices';
import { rejectAddiData } from '../api';
import { selectAddiDataRequestDetails } from '../selectors';

import { getAddiDataRequestDetailsSaga } from './getAddiDataRequestDetailsSaga';

export function* rejectAddiDataRequestSaga({ payload }: PayloadAction<{ rejectionReason: string }>): SagaIterator {
	const addiDataRequest = (yield select(selectAddiDataRequestDetails)) as ReturnType<typeof selectAddiDataRequestDetails>;

	console.log('in reject saga');

	if (addiDataRequest !== null) {
		try {
			yield put(addiDataRequestDetailsSlice.actions.rejectAddiDataRequestStart());

			yield call(rejectAddiData, { id: addiDataRequest.id, rejectionReason: payload.rejectionReason });
			yield call(getAddiDataRequestDetailsSaga, addiDataRequest.id);

			yield put(addiDataRequestDetailsSlice.actions.rejectAddiDataRequestEnd());
		} catch (e) {
			yield put(addiDataRequestDetailsSlice.actions.rejectAddiDataRequestEnd());
			yield call(handleErrorSaga, e);
		}
	}
}
