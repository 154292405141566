import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';

import {
	Box,
	Typography
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

import { ButtonDelete, DialogModal, LoaderButton } from 'components';
import { selectConfiguration } from 'features/requestAccess/selectors';


import { UpdateUserRequest, UserEditFormFields } from '../types';
import { usersManagementUserSlice } from '../slices';
import {
	selectUser,
	selectIsUsersManagementUserInitLoading,
	selectIsUserUpdateLoading,
	selectIsSendDuaSignRequestLoading
} from '../selectors';
import { getUserFormValidationSchema, mapUserToEditFormFields } from '../utils';

import { UserInfoEditSection } from './UserInfoEditSection';
import { UserDocuSign } from './UserDocuSign';
import { UserDuaEditSection } from './UserDuaEditSection';
import { UserNotesSection } from './UserNotesSection';
import { ByteUnitMultiples, convertBytes } from 'utils';
import { useDebouncedValidate } from 'hooks';

interface UsersDuaInfoPageParams {
	userId: string
}

type UsersDuaInfoPageProps = RouteComponentProps<UsersDuaInfoPageParams>;

export const UsersManagementUserPage: FunctionComponent<UsersDuaInfoPageProps> = ({
	match
}) => {
	const dispatch = useDispatch();

	const pageConfiguration = useSelector(selectConfiguration);
	const user = useSelector(selectUser);
	const isUserInitLoading = useSelector(selectIsUsersManagementUserInitLoading);
	const isUserUpdateLoading = useSelector(selectIsUserUpdateLoading);
	const isSendDuaSignRequestLoading = useSelector(selectIsSendDuaSignRequestLoading);

	const [isDeleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
	const [isSaveValidateModalOpen, setSaveValidateModalOpen] = useState(false);

	useEffect(() => {
		dispatch(usersManagementUserSlice.actions.initUsersManagementUser({ id: match.params.userId }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getUserUpdateFieldsFromFormFields = (values: UserEditFormFields): UpdateUserRequest => (
		{
			id: match.params.userId,
			data: {
				...values,
				threshold: `${convertBytes(Number(values.threshold) ?? 0, ByteUnitMultiples.GB, ByteUnitMultiples.Bytes)}`,
			}
		}
	);

	const formValidationSchema = useMemo(
		() => getUserFormValidationSchema(user, pageConfiguration),
		[user, pageConfiguration]);

	const form = useFormik<UserEditFormFields>({
		validateOnChange: false,
		initialValues: {
			userName: '',
			roles: [],
			threshold: '0',
			legacy: false,
			firstName: '',
			lastName: '',
			credentials: '',
			roleOnProject: '',
			workingEmail: '',
			institution: '',
			country: '',
			isBusinessOfficial: false,
			businessOfficialName: '',
			businessOfficialTitle: '',
			businessOfficialEmail: '',
			dataUseProposal: '',
			projectTimeframe: '',
			accessToGenomicsLevel1: false,
			accessToGenomicsLevel2: false,
			accessToGenomicsLevel3: false,
			accessToGenomicsLevel4: false,
			accessToEpigenomicsLevel1: false,
			accessToEpigenomicsLevel2: false,
			accessToEpigenomicsLevel3: false,
			accessToEpigenomicsLevel4: false,
			accessToProteomicsLevel1: false,
			accessToProteomicsLevel2: false,
			accessToProteomicsLevel4: false,
			accessToTranscriptomicsLevel1: false,
			accessToTranscriptomicsLevel2: false,
			accessToTranscriptomicsLevel3: false,
			accessToTranscriptomicsLevel4: false,
			notes: '',
			collaborators: []
		},
		validationSchema: formValidationSchema,
		onSubmit: values => {
			dispatch(usersManagementUserSlice.actions.updateUser(getUserUpdateFieldsFromFormFields(values)));
		}
	});

	const openDeleteUserModal = useCallback(() => setDeleteUserModalOpen(true), [setDeleteUserModalOpen]);
	const closeDeleteUserModal = useCallback(() => setDeleteUserModalOpen(false), [setDeleteUserModalOpen]);

	const openSaveValidateModal = useCallback(() => setSaveValidateModalOpen(true), [setSaveValidateModalOpen]);
	const closeSaveValidateModal = useCallback(() => setSaveValidateModalOpen(false), [setSaveValidateModalOpen]);

	const deleteUser = useCallback(() => dispatch(usersManagementUserSlice.actions.deleteUser()), [dispatch]);
	const saveUser = useCallback(() => {
		if (form.isValid) {
			form.handleSubmit();
		} else {
			openSaveValidateModal();
		}
	}, [form, openSaveValidateModal]);

	useEffect(() => {
		if (user !== null) {
			const initialValues = mapUserToEditFormFields(user);
			form.resetForm({
				values: initialValues,
				touched: Object.fromEntries(Object.entries(initialValues).map((m: [string, unknown]) => [m[0], true])),
				errors: {},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useDebouncedValidate({
		validate: async values => {
			await form.validateForm(values);
		},
		debounceTime: 500,
		values: form.values,
	});

	return (
		<Box>
			<Box py={3} px={5} width="100%">
				<Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
					<Typography component='h1' variant='h4'>
						{'Users Management'}
					</Typography>
					<Box display={'flex'}>
						<ButtonDelete
							variant="outlined"
							disabled={
								isUserInitLoading ||
								isUserUpdateLoading ||
								isSendDuaSignRequestLoading
							}
							onClick={openDeleteUserModal}
							startIcon={<DeleteIcon />}
						>
							{'Delete'}
						</ButtonDelete>
						<Box pr={3} />
						<LoaderButton
							isLoading={
								isUserInitLoading ||
								isUserUpdateLoading
							}
							ButtonProps={{
								disabled: isSendDuaSignRequestLoading,
								size: 'large',
								color: 'primary',
								variant: 'contained',
								onClick: saveUser
							}}
						>
							{'Save User'}
						</LoaderButton>
					</Box>
				</Box>
				{user &&
					<FormikProvider value={form}>
						<Box display="flex" flexWrap="wrap">
							<Box display="flex" flexDirection="column">
								<UserInfoEditSection
									user={user}
									form={form}
								/>
							</Box>
							{user.status !== null &&
								<Box display="flex" flexDirection="column">
									<UserNotesSection form={form} />
									<UserDocuSign
										docuSignViewLink={pageConfiguration.docuSignViewLink}
										user={user}
										form={form}
									/>
									<UserDuaEditSection
										user={user}
										form={form}
									/>
								</Box>
							}
						</Box>
					</FormikProvider>
				}
			</Box>

			{/* Modals */}
			<DialogModal
				isOpen={isDeleteUserModalOpen}
				headerText={'Delete User'}
				cancelButtonText={'Cancel'}
				submitButtonText={'Confirm'}
				onCancel={closeDeleteUserModal}
				onSubmit={deleteUser}
			>
				<Typography variant='h6'>
					{'Warning!'}
				</Typography>
				<Typography>
					{'You are going to delete this user. This action is irreversible and will delete user from Azure B2C, delete user data request, and move data request in DocuSign to \'Recycle Bin\' folder.'}
				</Typography>
				<Typography>
					{'Click \'Confirm\' to proceed or \'Cancel\' to close this dialog.'}
				</Typography>
			</DialogModal>
			<DialogModal
				isOpen={isSaveValidateModalOpen}
				headerText={'Save User'}
				cancelButtonText={'Ok'}
				onCancel={closeSaveValidateModal}
			>
				<Typography>
					{'User cannot be saved due to validation errors. Please check the highlighted fields and correct them to proceed.'}
				</Typography>
			</DialogModal>
		</Box>
	);
};
