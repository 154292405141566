import { createSlice } from '@reduxjs/toolkit';
import { AddiDataRequestShort, GetAddiDataListResponse } from '../types';
import { SagaEndPayloadAction, SagaEndState } from 'types';

interface AddiDataRequestsListState {
	addiDataRequests: AddiDataRequestShort[]
	currentPage: number
	rowsPerPage: number
	totalRowsCount: number

	isAddiDataRequestsLoading: boolean
}

const initialState: AddiDataRequestsListState = {
	addiDataRequests: [],
	currentPage: 0,
	rowsPerPage: 20,
	totalRowsCount: 0,

	isAddiDataRequestsLoading: false
};

export const addiDataRequestsListSlice = createSlice({
	name: 'addiDataRequests.list',
	initialState,
	reducers: {
		getAddiDataRequestsList: () => undefined,
		getAddiDataRequestsListStart: (state: AddiDataRequestsListState) => {
			state.isAddiDataRequestsLoading = true;
			console.log('in action start');
		},
		getAddiDataRequestsListEnd: (state: AddiDataRequestsListState, { payload }: SagaEndPayloadAction<GetAddiDataListResponse>) => {
			if (payload.endState === SagaEndState.Success) {
				state.addiDataRequests = payload.data.addiDataRequests;
				state.totalRowsCount = payload.data.totalCount;

				console.log('in action response', payload.data.addiDataRequests);
			}
			state.isAddiDataRequestsLoading = false;
		}
	}
});
