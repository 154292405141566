/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Step,
	StepLabel,
	Stepper,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TextField,
	Typography
} from '@material-ui/core';
import { ButtonDelete, DialogModal } from 'components';
import { FieldArray, FormikProps, getIn, useFormik } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MAX_REJECTION_REASON_LENGTH } from '../constants';
import { selectIsUserDuaApproving, selectIsUserDuaRejecting, selectIsUsersManagementLoading } from '../selectors';
import { usersManagementUserSlice } from '../slices';
import { DuaStatuses, User, UserEditFormFields } from '../types';
import * as yup from 'yup';
import { OmicCard, OmicCardsInfo } from 'features/requestAccess/components';
import { selectConfiguration } from 'features/requestAccess/selectors';
import { ResearcherSurvey } from './ResearcherSurvey';
import { trim } from 'lodash';

const rejectConfirmationValidationSchema = yup.object().shape({
	rejectionReason: yup.string()
		.trim()
		.max(MAX_REJECTION_REASON_LENGTH, `Max length is ${MAX_REJECTION_REASON_LENGTH}`)
		.required('Please fill the data'),
});

export interface UserDuaEditSectionProps {
	user: User
	form: FormikProps<UserEditFormFields>
}

export const UserDuaEditSection: FunctionComponent<UserDuaEditSectionProps> = ({
	user,
	form
	// eslint-disable-next-line sonarjs/cognitive-complexity
}) => {

	const PRINCIPAL_INVESTIGATOR = 'Principal Investigator';
	const DATA_USER = 'Data User';

	const ROLE_ON_PROJECT = 'Role On Project';

	const maxCollaborators = 20;
	const dispatch = useDispatch();

	const pageConfiguration = useSelector(selectConfiguration);
	const isUserDuaApproving = useSelector(selectIsUserDuaApproving);
	const isUserDuaRejecting = useSelector(selectIsUserDuaRejecting);
	const isUsersManagementLoading = useSelector(selectIsUsersManagementLoading);

	const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
	const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

	const rejectConfirmationForm = useFormik<{ rejectionReason: string }>({
		initialValues: {
			rejectionReason: ''
		},
		validationSchema: rejectConfirmationValidationSchema,
		onSubmit: values => {
			dispatch(usersManagementUserSlice.actions.rejectUserDua({
				rejectionReason: values.rejectionReason
			}));
		}
	});

	React.useEffect(() => {
		if (form.values.isBusinessOfficial) {
			void form.setFieldValue('businessOfficialName', `${form.values.firstName} ${form.values.lastName}`);
		} else {
			void form.setFieldValue('businessOfficialName', trim(form.values.businessOfficialName));
		}
	}, [form.values.isBusinessOfficial, form.values.firstName, form.values.lastName]);

	React.useEffect(() => {
		if (form.values.isBusinessOfficial) {
			void form.setFieldValue('businessOfficialTitle', form.values.credentials);
		}
	}, [form.values.isBusinessOfficial, form.values.credentials]);

	React.useEffect(() => {
		if (form.values.isBusinessOfficial) {
			void form.setFieldValue('businessOfficialEmail', form.values.workingEmail);
		}

	}, [form.values.isBusinessOfficial, form.values.workingEmail]);

	const onOmicCardLevelClick = (formFieldName: string) => {
		void form.setFieldValue(formFieldName, !form.values[formFieldName as keyof UserEditFormFields]);
	};

	const onApproveClick = useCallback(() => {
		setIsApproveModalOpen(true);
	}, []);
	const onRejectClick = useCallback(() => {
		void rejectConfirmationForm.setFieldValue('rejectionReason', '');
		void rejectConfirmationForm.setFieldTouched('rejectionReason', false);
		setIsRejectModalOpen(true);
	}, [rejectConfirmationForm]);

	const onApproveConfirmationSubmit = useCallback(() => {
		dispatch(usersManagementUserSlice.actions.approveUserDua());
		setIsApproveModalOpen(false);
	}, [dispatch]);
	const onApproveConfirmationCancel = useCallback(() => {
		setIsApproveModalOpen(false);
	}, []);

	const onRejectConfirmationSubmit = useCallback(() => {
		rejectConfirmationForm.handleSubmit();
		if (rejectConfirmationForm.isValid) {
			setIsRejectModalOpen(false);
		}
	}, [rejectConfirmationForm]);
	const onRejectConfirmationCancel = useCallback(() => {
		setIsRejectModalOpen(false);
	}, []);

	function getFormattedDate(date: string | null) {
		return date !== null ?
			new Intl.DateTimeFormat('default', {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric'
			}).format(new Date(date)) :
			'';
	}

	const tableData: { name: string; data: string }[] = [
		{ name: 'Status', data: user.status ?? '' },
		{ name: 'Submit Date', data: getFormattedDate(user.lastSubmitDate) },
		{ name: 'Last Modification Date', data: getFormattedDate(user.lastModificationDate) },
	];

	if (user.updatedBy) {
		tableData.push({ name: 'Request Updated Date', data: getFormattedDate(user.lastModificationDate) });
		tableData.push({ name: 'Request Updated By', data: user.updatedBy });
	}

	if (user.status !== DuaStatuses.Submitted) {
		tableData.push({ name: 'Verified Date', data: getFormattedDate(user.verifiedDate) });
		tableData.push({ name: 'Verified By', data: user.verifiedBy ?? '' });
		tableData.push({ name: 'Requester Sign Status', data: user.userSignStatus ?? '' });
		tableData.push({ name: 'Requester Sign Date', data: getFormattedDate(user.userSignDate) });
		tableData.push({ name: 'Auth. Business Official Sign Status', data: user.businessOfficialSignStatus ?? '' });
		tableData.push({ name: 'Auth. Business Official Sign Date', data: getFormattedDate(user.businessOfficialSignDate) });
		tableData.push({ name: 'Answer ALS Official Sign Status', data: user.answerOfficialSignStatus ?? '' });
		tableData.push({ name: 'Answer ALS Official Sign Date', data: getFormattedDate(user.answerOfficialSignDate) });
	}

	if (user.status === DuaStatuses.Rejected && user.rejectionReason !== null) {
		tableData.splice(1, 0, { name: 'Rejection Reason', data: user.rejectionReason });
		tableData.push({ name: 'Rejected Date', data: getFormattedDate(user.rejectedDate) });
		tableData.push({ name: 'Rejected By', data: user.rejectedBy ?? '' });
	}

	if (user.status === DuaStatuses.Approved) {
		tableData.push({ name: 'Approved Date', data: getFormattedDate(user.approvedDate) });
		tableData.push({ name: 'Approved By', data: user.approvedBy ?? '' });
	}

	const duaSteps = ['Submitted', 'Verified', 'Signed', 'Approved/Rejected'];
	const getActiveStep = () => {
		switch (user.status) {
			case DuaStatuses.Submitted:
				return 0;
			case DuaStatuses.Verified:
				return 1;
			case DuaStatuses.Signed:
				return 2;
			case DuaStatuses.Approved:
			case DuaStatuses.Rejected:
				// eslint-disable-next-line @typescript-eslint/no-magic-numbers
				return 3;
		}
	};

	return (
		<Box width={630}>
			<Box mr={3}>
				<Paper>
					<Box p={2}>
						<Box mb={3.5} display="flex" justifyContent="space-between" alignItems="center">
							<Typography
								variant="h5"
								component="h2"
							>
								{'Data Request'}
							</Typography>
							<Box>
								<ButtonGroup disableElevation variant="outlined" color="primary" size="large">
									<Button
										disabled={user.status === DuaStatuses.Rejected || isUserDuaRejecting}
										size="medium"
										onClick={onRejectClick}
									>
										{'Reject'}
									</Button>
									<Button
										disabled={user.status === DuaStatuses.Approved || isUserDuaApproving}
										size="medium"
										onClick={onApproveClick}
									>
										{'Approve'}
									</Button>
								</ButtonGroup>
							</Box>
						</Box>
						<Box>
							<Stepper activeStep={getActiveStep()} alternativeLabel nonLinear >
								{duaSteps.map(label =>
									<Step key={label}>
										<StepLabel>{label}</StepLabel>
									</Step>
								)}
							</Stepper>
						</Box>
						<Box mb={2} />
						<Box mb={2}>
							<Table size="small">
								<TableBody>
									{tableData.map(row =>
										<TableRow key={row.name}>
											<TableCell component="th">
												{row.name}
											</TableCell>
											<TableCell style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: row.data }}>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</Box>
						<ResearcherSurvey user={user} />
						<Box mb={2} />
						<Box display={'flex'}>
							<TextField
								name="firstName"
								id="firstName-input"
								label={'First Name'}
								margin='normal'
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								variant='outlined'
								value={form.values.firstName}
								onChange={form.handleChange}
								onBlur={form.handleBlur}
								error={form.touched.firstName && Boolean(form.errors.firstName)}
								helperText={form.touched.firstName && form.errors.firstName}
								disabled={isUsersManagementLoading}
							/>
							<Box pr={2} />
							<TextField
								name="lastName"
								id="lastName-input"
								label={'Last Name'}
								margin='normal'
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								variant='outlined'
								value={form.values.lastName}
								onChange={form.handleChange}
								onBlur={form.handleBlur}
								error={form.touched.lastName && Boolean(form.errors.lastName)}
								helperText={form.touched.lastName && form.errors.lastName}
								disabled={isUsersManagementLoading}
							/>
						</Box>
						<Box>
							<TextField
								name="credentials"
								id="credentials-input"
								label={'Credentials'}
								margin='normal'
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								variant='outlined'
								value={form.values.credentials}
								onChange={form.handleChange}
								onBlur={form.handleBlur}
								error={form.touched.credentials && Boolean(form.errors.credentials)}
								helperText={form.touched.credentials && form.errors.credentials}
								disabled={isUsersManagementLoading}
							/>
						</Box>
						<Box pt={2} pb={1}>
							<FormControl
								variant="outlined"
								fullWidth
								disabled={isUsersManagementLoading}
								error={Boolean(form.errors.roleOnProject)}>
								<InputLabel id="role-on-project-input-label">{ROLE_ON_PROJECT}</InputLabel>
								<Select labelId="role-on-project-label"
									name="roleOnProject"
									id="role-on-project-select"
									value={form.values.roleOnProject}
									onChange={form.handleChange}
									label={ROLE_ON_PROJECT}
									error={Boolean(form.errors.roleOnProject)}
								>
									<MenuItem value={PRINCIPAL_INVESTIGATOR}>{PRINCIPAL_INVESTIGATOR}</MenuItem>
									<MenuItem value={DATA_USER}>{DATA_USER}</MenuItem>
								</Select>
								<FormHelperText>
									{form.errors.roleOnProject}
								</FormHelperText>
							</FormControl>
						</Box>
						<Box>
							<TextField
								name="workingEmail"
								id="workingEmail-input"
								label={'Working Email'}
								margin='normal'
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								variant='outlined'
								value={form.values.workingEmail}
								onChange={form.handleChange}
								onBlur={form.handleBlur}
								error={form.touched.workingEmail && Boolean(form.errors.workingEmail)}
								helperText={form.touched.workingEmail && form.errors.workingEmail}
								disabled={isUsersManagementLoading}
							/>
						</Box>
						<Box>
							<TextField
								name="institution"
								id="institution-input"
								label={'Institution'}
								margin='normal'
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								variant='outlined'
								value={form.values.institution}
								onChange={form.handleChange}
								onBlur={form.handleBlur}
								error={form.touched.institution && Boolean(form.errors.institution)}
								helperText={form.touched.institution && form.errors.institution}
								disabled={isUsersManagementLoading}
							/>
						</Box>
						<Box>
							<TextField
								name="country"
								id="country-input"
								label={'Institution Country'}
								margin='normal'
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								variant='outlined'
								value={form.values.country}
								onChange={form.handleChange}
								onBlur={form.handleBlur}
								error={form.touched.country && Boolean(form.errors.country)}
								helperText={form.touched.country && form.errors.country}
								disabled={isUsersManagementLoading}
							/>
						</Box>
						<Box>
							<FormControl>
								<FormControlLabel
									control={
										<Checkbox
											name="isBusinessOfficial"
											checked={form.values.isBusinessOfficial}
											onChange={form.handleChange}
											color="primary"
										/>
									}
									label={
										<Typography component="span" variant="body2" style={{ display: 'block', fontWeight: 400 }}>
											{'User stated that they are the Auth. Business Official for this data request.'}
										</Typography>
									}
								/>
							</FormControl>
						</Box>
						<Box>
							<TextField
								name="businessOfficialName"
								id="businessOfficialName-input"
								label={'Auth. Business Official Name'}
								margin='normal'
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								variant='outlined'
								value={form.values.businessOfficialName}
								onChange={form.handleChange}
								onBlur={form.handleBlur}
								error={form.touched.businessOfficialName && Boolean(form.errors.businessOfficialName)}
								helperText={form.touched.businessOfficialName && form.errors.businessOfficialName}
								disabled={isUsersManagementLoading || form.values.isBusinessOfficial}
							/>
						</Box>
						<Box>
							<TextField
								name="businessOfficialTitle"
								id="businessOfficialTitle-input"
								label={'Auth. Business Official Title'}
								margin='normal'
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								variant='outlined'
								value={form.values.businessOfficialTitle}
								onChange={form.handleChange}
								onBlur={form.handleBlur}
								error={form.touched.businessOfficialTitle && Boolean(form.errors.businessOfficialTitle)}
								helperText={form.touched.businessOfficialTitle && form.errors.businessOfficialTitle}
								disabled={isUsersManagementLoading || form.values.isBusinessOfficial}
							/>
						</Box>
						<Box>
							<TextField
								name="businessOfficialEmail"
								id="businessOfficialEmail-input"
								label={'Auth. Business Official Email'}
								margin='normal'
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								variant='outlined'
								value={form.values.businessOfficialEmail}
								onChange={form.handleChange}
								onBlur={form.handleBlur}
								error={form.touched.businessOfficialEmail && Boolean(form.errors.businessOfficialEmail)}
								helperText={form.touched.businessOfficialEmail && form.errors.businessOfficialEmail}
								disabled={isUsersManagementLoading || form.values.isBusinessOfficial}
							/>
						</Box>
						<Box>
							<TextField
								name="dataUseProposal"
								id="dataUseProposal-input"
								label={'Data Use Proposal'}
								margin='normal'
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								variant='outlined'
								multiline
								value={form.values.dataUseProposal}
								onChange={form.handleChange}
								onBlur={form.handleBlur}
								error={form.touched.dataUseProposal && Boolean(form.errors.dataUseProposal)}
								helperText={form.touched.dataUseProposal && form.errors.dataUseProposal}
								disabled={isUsersManagementLoading}
							/>
						</Box>
						<Box>
							<TextField
								name="projectTimeframe"
								id="projectTimeframe-input"
								label={'Project Timeframe'}
								margin='normal'
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								variant='outlined'
								value={form.values.projectTimeframe}
								onChange={form.handleChange}
								onBlur={form.handleBlur}
								error={form.touched.projectTimeframe && Boolean(form.errors.projectTimeframe)}
								helperText={form.touched.projectTimeframe && form.errors.projectTimeframe}
								disabled={isUsersManagementLoading}
							/>
						</Box>
					</Box>
				</Paper>
			</Box>
			<Box pb={3} />
			<FieldArray name='collaborators'>
				{({ push, remove }) =>
					<Box>
						{
							form.values.collaborators.length < maxCollaborators &&
							<Box>
								<Button
									type="button"
									variant='outlined'
									color='primary'
									// eslint-disable-next-line react/jsx-no-bind
									onClick={() => {
										push({ name: '', email: '', title: '' });
									}}
								>
									{'Add collaborator'}
								</Button>
								<Box pb={2} />
							</Box>
						}
						{
							form.values.collaborators.map((value, index: number) => {
								const collabName = `collaborators[${index}].name`;
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								const errorCollabName: string = getIn(form.errors, collabName);

								const collabTitle = `collaborators[${index}].title`;
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								const errorCollabTitle: string = getIn(form.errors, collabTitle);

								const collabRoleOnProject = `collaborators[${index}].roleOnProject`;
								const errorCollabRoleOnProject: string = getIn(form.errors, collabRoleOnProject);

								const collabEmail = `collaborators[${index}].email`;
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								const errorCollabEmail: string = getIn(form.errors, collabEmail);

								const collaboratorReadOnlyData = user.collaborators?.filter(f => f.id === value.id)[0];
								const collaboratorTableData: { name: string; data: string }[] = [
									{ name: 'Sign Status', data: collaboratorReadOnlyData?.signStatus ?? '' },
									{ name: 'Sign Date', data: collaboratorReadOnlyData?.signDate ?? '' },
								];

								return <Box mr={3} key={index}>
									<Paper>
										<Box p={2}>
											<Typography
												variant="h5"
												component="h2"
											>
												{`Collaborator ${index + 1}`}
											</Typography>
											<Box pb={2} />
											{value.id && <Box mb={4}>
												<Table size="small">
													<TableBody>
														{collaboratorTableData.map(row =>
															<TableRow key={row.name}>
																<TableCell component="th">
																	{row.name}
																</TableCell>
																<TableCell style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: row.data }}>
																</TableCell>
															</TableRow>
														)}
													</TableBody>
												</Table>
											</Box>}
											<Box>
												<TextField
													label={'Name'}
													variant="outlined"
													size="small"
													fullWidth
													name={`collaborators[${index}].name`}
													onChange={form.handleChange}
													onBlur={form.handleBlur}
													value={value.name}
													helperText={errorCollabName}
													error={Boolean(errorCollabName)}
												/>
												<Box pb={3} />
												<TextField
													label={'Title'}
													variant="outlined"
													size="small"
													fullWidth
													name={`collaborators[${index}].title`}
													onChange={form.handleChange}
													onBlur={form.handleBlur}
													value={value.title}
													helperText={errorCollabTitle}
													error={Boolean(errorCollabTitle)}
												/>
												<Box pb={3} />
												<FormControl
													variant="outlined"
													fullWidth
													size="small"
													error={Boolean(errorCollabRoleOnProject)}
												>
													<InputLabel id="role-on-project-input-label">{ROLE_ON_PROJECT}</InputLabel>
													<Select labelId="role-on-project-label"
														name={collabRoleOnProject}
														id="role-on-project-select"
														value={value.roleOnProject}
														onChange={form.handleChange}
														label="Role On Project"
													>
														<MenuItem value={PRINCIPAL_INVESTIGATOR}>{PRINCIPAL_INVESTIGATOR}</MenuItem>
														<MenuItem value={DATA_USER}>{DATA_USER}</MenuItem>
													</Select>
													<FormHelperText>
														{errorCollabRoleOnProject}
													</FormHelperText>
												</FormControl>
												<Box pb={3} />
												<TextField
													label={'Email'}
													variant="outlined"
													size="small"
													fullWidth
													name={`collaborators[${index}].email`}
													onChange={form.handleChange}
													onBlur={form.handleBlur}
													value={value.email}
													// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
													helperText={errorCollabEmail}
													error={Boolean(errorCollabEmail)}
												/>
											</Box>
											<Box pb={3} />
											<Box display="flex" alignItems="flex-end">
												<ButtonDelete
													type='button'
													variant='outlined'
													// eslint-disable-next-line react/jsx-no-bind
													onClick={() => {
														remove(index);
													}}>
													{'remove'}
												</ButtonDelete>
											</Box>
										</Box>
									</Paper>
									<Box pb={4} />
								</Box>;
							})
						}
					</Box>
				}
			</FieldArray>
			<Box pb={3} />
			<Box display="flex" flexWrap="wrap" justifyContent="space-between">
				<OmicCardsInfo
					omicsConfiguration={pageConfiguration.markup.formBlock.supportedDownloads.omics}
				>
					{({ formOmicCards }) => formOmicCards.map((cardInfo, cardIndex) =>
						<OmicCard
							key={cardIndex}
							cardName={cardInfo.name}
							levels={cardInfo.levels.map(level => ({
								...level,
								checkboxValue: Boolean(form.values[level.formFieldName])
							}))}
							wrapperBoxProps={{
								minWidth: 291,
								mb: 3,
								mr: 3
							}}
							/* eslint-disable react/jsx-no-bind */
							// eslint-disable-next-line @typescript-eslint/no-empty-function
							onLevelClick={onOmicCardLevelClick} />
					)}
				</OmicCardsInfo>
			</Box>
			<DialogModal
				isOpen={isApproveModalOpen}
				headerText={'Approve User'}
				cancelButtonText={'Cancel'}
				submitButtonText={'Submit'}
				onCancel={onApproveConfirmationCancel}
				onSubmit={onApproveConfirmationSubmit}
			>
				<Typography component="span" variant="subtitle1">
					{`Please confirm user approval. ${user.userName} will become a researcher.`}
				</Typography>
			</DialogModal>
			<DialogModal
				isOpen={isRejectModalOpen}
				headerText={'Reject User'}
				cancelButtonText={'Cancel'}
				submitButtonText={'Submit'}
				onCancel={onRejectConfirmationCancel}
				onSubmit={onRejectConfirmationSubmit}
			>
				<Box minWidth={500}>
					<Box mb={2}>
						<Typography component="span" variant="subtitle1">
							{'Please define the rejection reason.'}
						</Typography>
					</Box>
					<TextField
						name="rejectionReason"
						id={'request-access-form-field-rejectionReason'}
						label={'Rejection Reason'}
						variant="outlined"
						multiline
						fullWidth
						value={rejectConfirmationForm.values.rejectionReason}
						onChange={rejectConfirmationForm.handleChange}
						helperText={rejectConfirmationForm.touched.rejectionReason && rejectConfirmationForm.errors.rejectionReason || 'Entered information will be sent to the user in the email body'}
						error={rejectConfirmationForm.touched.rejectionReason && Boolean(rejectConfirmationForm.errors.rejectionReason)}
					/>
				</Box>
			</DialogModal>
		</Box>);
};
