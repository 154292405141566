
import { User, UserEditFormFields } from '../types';
import { Collaborator } from '../types/User';
import { CollaboratorEditFormFields } from '../types/UpdateUser';
import { convertBytes } from 'utils';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const mapUserToEditFormFields = (user: User): UserEditFormFields => ({
	userName: user.userName ?? '',
	roles: user.roles ?? [],
	threshold: String(convertBytes(user.threshold === null ? 0 : user.threshold)),
	legacy: user.legacy ?? false,
	firstName: user.firstName ?? '',
	lastName: user.lastName ?? '',
	credentials: user.credentials ?? '',
	roleOnProject: user.roleOnProject ?? '',
	workingEmail: user.workingEmail ?? '',
	institution: user.institution ?? '',
	country: user.country ?? '',
	isBusinessOfficial: user.isBusinessOfficial ?? false,
	businessOfficialName: user.businessOfficialName ?? '',
	businessOfficialTitle: user.businessOfficialTitle ?? '',
	businessOfficialEmail: user.businessOfficialEmail ?? '',
	dataUseProposal: user.dataUseProposal ?? '',
	projectTimeframe: user.projectTimeframe ?? '',
	accessToGenomicsLevel1: user.accessToGenomicsLevel1 ?? false,
	accessToGenomicsLevel2: user.accessToGenomicsLevel2 ?? false,
	accessToGenomicsLevel3: user.accessToGenomicsLevel3 ?? false,
	accessToGenomicsLevel4: user.accessToGenomicsLevel4 ?? false,
	accessToEpigenomicsLevel1: user.accessToEpigenomicsLevel1 ?? false,
	accessToEpigenomicsLevel2: user.accessToEpigenomicsLevel2 ?? false,
	accessToEpigenomicsLevel3: user.accessToEpigenomicsLevel3 ?? false,
	accessToEpigenomicsLevel4: user.accessToEpigenomicsLevel4 ?? false,
	accessToTranscriptomicsLevel1: user.accessToTranscriptomicsLevel1 ?? false,
	accessToTranscriptomicsLevel2: user.accessToTranscriptomicsLevel2 ?? false,
	accessToTranscriptomicsLevel3: user.accessToTranscriptomicsLevel3 ?? false,
	accessToTranscriptomicsLevel4: user.accessToTranscriptomicsLevel4 ?? false,
	accessToProteomicsLevel1: user.accessToProteomicsLevel1 ?? false,
	accessToProteomicsLevel2: user.accessToProteomicsLevel2 ?? false,
	accessToProteomicsLevel4: user.accessToProteomicsLevel4 ?? false,
	notes: user.notes ?? '',
	collaborators: user.collaborators !== null ? user.collaborators?.map((c: Collaborator): CollaboratorEditFormFields => ({
		id: c.id ?? '',
		name: c.name ?? '',
		title: c.title ?? '',
		roleOnProject: c.roleOnProject ?? '',
		email: c.email ?? ''
	})) : []
});
