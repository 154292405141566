/* es-lint-disable sonarjs/cognitive-complexity */
import React, { FunctionComponent } from 'react';

import { MarkupFormFields, UiFormFields } from '../types';
import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from '@material-ui/core';
import { FormikProps } from 'formik';
import { trim } from 'lodash';

interface DuaFormFieldsProps {

	formFieldsConfiguration: MarkupFormFields
	form: FormikProps<UiFormFields>
	isReadonly?: boolean
}

// const formFieldsSkeleton: {
// markupFormFieldName: keyof MarkupFormFields
// formFieldName: keyof UiFormFields
// isMultiline?: boolean
// }[] = [
// { markupFormFieldName: 'workingEmail', formFieldName: 'workingEmail' },
// { markupFormFieldName: 'institution', formFieldName: 'institution' },
// { markupFormFieldName: 'country', formFieldName: 'country' },
// { markupFormFieldName: 'businessOfficialName', formFieldName: 'businessOfficialName' },
// { markupFormFieldName: 'businessOfficialTitle', formFieldName: 'businessOfficialTitle' },
// { markupFormFieldName: 'businessOfficialEmail', formFieldName: 'businessOfficialEmail' },
// { markupFormFieldName: 'dataUseProposal', formFieldName: 'dataUseProposal', isMultiline: true },
// { markupFormFieldName: 'projectTimeframe', formFieldName: 'projectTimeframe' },
// ];

export const DuaFormFields: FunctionComponent<DuaFormFieldsProps> = ({
	formFieldsConfiguration,
	form,
	isReadonly = false,
}: DuaFormFieldsProps) => {

	React.useEffect(() => {
		if (form.values.isBusinessOfficial) {
			void form.setFieldValue('businessOfficialName', `${form.values.firstName} ${form.values.lastName}`);
		} else {
			void form.setFieldValue('businessOfficialName', trim(form.values.businessOfficialName));
		}
	}, [form.values.isBusinessOfficial, form.values.firstName, form.values.lastName]);

	React.useEffect(() => {
		if (form.values.isBusinessOfficial) {
			void form.setFieldValue('businessOfficialTitle', form.values.credentials);
		}
	}, [form.values.isBusinessOfficial, form.values.credentials]);

	React.useEffect(() => {
		if (form.values.isBusinessOfficial) {
			void form.setFieldValue('businessOfficialEmail', form.values.workingEmail);
		}

	}, [form.values.isBusinessOfficial, form.values.workingEmail]);

	return <Box>
		<Box display="flex" pb={4.5}>
			<TextField
				name="firstName"
				id={'request-access-form-field-firstName'}
				label={formFieldsConfiguration.firstName.label}
				variant="outlined"
				fullWidth
				value={form.values.firstName}
				onChange={form.handleChange}
				helperText={form.touched.firstName && form.errors.firstName || formFieldsConfiguration.firstName.helperText}
				error={form.touched.firstName && Boolean(form.errors.firstName)}
				disabled={isReadonly}
			/>
			<Box pr={4} />
			<TextField
				name="lastName"
				id={'request-access-form-field-lastName'}
				label={formFieldsConfiguration.lastName.label}
				variant="outlined"
				fullWidth
				value={form.values.lastName}
				onChange={form.handleChange}
				helperText={form.touched.lastName && form.errors.lastName || formFieldsConfiguration.lastName.helperText}
				error={form.touched.lastName && Boolean(form.errors.lastName)}
				disabled={isReadonly}
			/>
		</Box>
		<Box pb={2.5} />
		<Box>
			<TextField
				name="credentials"
				id={'request-access-form-field-lastName'}
				label={formFieldsConfiguration.credentials.label}
				variant="outlined"
				fullWidth
				value={form.values.credentials}
				onChange={form.handleChange}
				helperText={form.touched.credentials && form.errors.credentials || formFieldsConfiguration.credentials.helperText}
				error={form.touched.credentials && Boolean(form.errors.credentials)}
				disabled={isReadonly}
			/>
		</Box>
		<Box pb={2.5} />
		<Box pb={4.5}>
			<FormControl
				variant="outlined"
				fullWidth
				error={form.touched.roleOnProject && Boolean(form.errors.roleOnProject)}>
				<InputLabel id="role-on-project-input-label">{formFieldsConfiguration.roleOnProject.label}</InputLabel>
				<Select labelId="role-on-project-label"
					name="roleOnProject"
					id="role-on-project-select"
					value={form.values.roleOnProject}
					onChange={form.handleChange}
					label={formFieldsConfiguration.roleOnProject.label}
					error={form.touched.roleOnProject && Boolean(form.errors.roleOnProject)}
					disabled={isReadonly}
				>
					{formFieldsConfiguration.roleOnProject.values.map((item: string) =>
						<MenuItem key={item} value={item}>
							{item}
						</MenuItem>
					)}
				</Select>
				<FormHelperText>
					{form.touched.roleOnProject && form.errors.roleOnProject || formFieldsConfiguration.roleOnProject.helperText}
				</FormHelperText>
			</FormControl>
		</Box>
		<Box pb={4.5}>
			<TextField
				name={'workingEmail'}
				id={'request-access-form-field-workingEmail'}
				label={formFieldsConfiguration.workingEmail.label}
				variant="outlined"
				fullWidth
				value={form.values.workingEmail}
				onChange={form.handleChange}
				helperText={form.touched.workingEmail && form.errors.workingEmail || formFieldsConfiguration.workingEmail.helperText}
				error={form.touched.workingEmail && Boolean(form.errors.workingEmail)}
				disabled={isReadonly}
			/>
		</Box>
		<Box pb={4.5}>
			<TextField
				name={'institution'}
				id={'request-access-form-field-institution'}
				label={formFieldsConfiguration.institution.label}
				variant="outlined"
				fullWidth
				value={form.values.institution}
				onChange={form.handleChange}
				helperText={form.touched.institution && form.errors.institution || formFieldsConfiguration.institution.helperText}
				error={form.touched.institution && Boolean(form.errors.institution)}
				disabled={isReadonly}
			/>
		</Box>
		<Box pb={4.5}>
			<TextField
				name={'country'}
				id={'request-access-form-field-country'}
				label={formFieldsConfiguration.country.label}
				variant="outlined"
				fullWidth
				value={form.values.country}
				onChange={form.handleChange}
				helperText={form.touched.country && form.errors.country || formFieldsConfiguration.country.helperText}
				error={form.touched.country && Boolean(form.errors.country)}
				disabled={isReadonly}
			/>
		</Box>
		<Box>
			<FormControl>
				<FormControlLabel
					control={
						<Checkbox
							name="isBusinessOfficial"
							checked={form.values.isBusinessOfficial}
							onChange={form.handleChange}
							color="primary"
						/>
					}
					label={
						<Typography component="span" variant="body2" style={{ display: 'block', fontWeight: 400 }}>
							{formFieldsConfiguration.isBusinessOfficial.label}
						</Typography>
					}
				/>
				<FormHelperText>
					{formFieldsConfiguration.isBusinessOfficial.helperText}
				</FormHelperText>
			</FormControl>
		</Box>
		<Box pb={4.5}>
			<TextField
				name={'businessOfficialName'}
				id={'request-access-form-field-businessOfficialName'}
				label={formFieldsConfiguration.businessOfficialName.label}
				variant="outlined"
				fullWidth
				value={form.values.businessOfficialName}
				onChange={form.handleChange}
				helperText={form.touched.businessOfficialName && form.errors.businessOfficialName || formFieldsConfiguration.businessOfficialName.helperText}
				error={form.touched.businessOfficialName && Boolean(form.errors.businessOfficialName)}
				disabled={isReadonly || form.values.isBusinessOfficial}
			/>
		</Box>
		<Box pb={4.5}>
			<TextField
				name={'businessOfficialTitle'}
				id={'request-access-form-field-businessOfficialTitle'}
				label={formFieldsConfiguration.businessOfficialTitle.label}
				variant="outlined"
				fullWidth
				value={form.values.businessOfficialTitle}
				onChange={form.handleChange}
				helperText={form.touched.businessOfficialTitle && form.errors.businessOfficialTitle || formFieldsConfiguration.businessOfficialTitle.helperText}
				error={form.touched.businessOfficialTitle && Boolean(form.errors.businessOfficialTitle)}
				disabled={isReadonly || form.values.isBusinessOfficial}
			/>
		</Box>
		<Box pb={4.5}>
			<TextField
				name={'businessOfficialEmail'}
				id={'request-access-form-field-businessOfficialEmail'}
				label={formFieldsConfiguration.businessOfficialEmail.label}
				variant="outlined"
				fullWidth
				value={form.values.businessOfficialEmail}
				onChange={form.handleChange}
				helperText={form.touched.businessOfficialEmail && form.errors.businessOfficialEmail || formFieldsConfiguration.businessOfficialEmail.helperText}
				error={form.touched.businessOfficialEmail && Boolean(form.errors.businessOfficialEmail)}
				disabled={isReadonly || form.values.isBusinessOfficial}
			/>
		</Box>
		<Box pb={4.5}>
			<TextField
				name={'dataUseProposal'}
				id={'request-access-form-field-dataUseProposal'}
				label={formFieldsConfiguration.dataUseProposal.label}
				variant="outlined"
				fullWidth
				multiline={true}
				value={form.values.dataUseProposal}
				onChange={form.handleChange}
				helperText={form.touched.dataUseProposal && form.errors.dataUseProposal || formFieldsConfiguration.dataUseProposal.helperText}
				error={form.touched.dataUseProposal && Boolean(form.errors.dataUseProposal)}
				disabled={isReadonly}
			/>
		</Box>
		<Box pb={4.5}>
			<TextField
				name={'projectTimeframe'}
				id={'request-access-form-field-projectTimeframe'}
				label={formFieldsConfiguration.projectTimeframe.label}
				variant="outlined"
				fullWidth
				value={form.values.projectTimeframe}
				onChange={form.handleChange}
				helperText={form.touched.projectTimeframe && form.errors.projectTimeframe || formFieldsConfiguration.projectTimeframe.helperText}
				error={form.touched.projectTimeframe && Boolean(form.errors.projectTimeframe)}
				disabled={isReadonly}
			/>
		</Box>
		{/* {formFieldsSkeleton.map(({ markupFormFieldName, formFieldName, isMultiline }, index) => {
			const markupFormField = formFieldsConfiguration[markupFormFieldName];

			return (
				<Fragment key={index}>
					<TextField
						name={ formFieldName }
						id={ `request-access-form-field-${markupFormFieldName}` }
						label={ markupFormField.label }
						variant="outlined"
						fullWidth
						multiline={ Boolean(isMultiline) }
						value={ form.values[formFieldName] }
						onChange={ form.handleChange }
						helperText={ form.touched[formFieldName] && form.errors[formFieldName] || markupFormField.helperText }
						error={ form.touched[formFieldName] && Boolean(form.errors[formFieldName]) }
						disabled={ isReadonly }
					/>
					{index !== formFieldsSkeleton.length - 1 && <Box pb={4.5} /> }
				</Fragment>
			);
		})} */}
	</Box>;
};
