import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from 'network';

import { GetAddiDataListRequest, GetAddiDataListResponse } from '../types';

export function* getAddiDataRequestsList(params: GetAddiDataListRequest): SagaIterator<AxiosResponse<GetAddiDataListResponse>> {
	const method: Method = 'POST';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		data: params
	};

	console.log('in api');

	return (yield call(networkManager.fetch, Urls.GetAddiDataRequestsList, requestOptions)) as AxiosResponse<GetAddiDataListResponse>;
}
