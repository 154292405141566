import type { RootState } from 'app/store';
import { AddiDataRequest, AddiDataRequestShort } from './types';

export const selectAddiDataRequestsList = (state: RootState): AddiDataRequestShort[] => state.administration.addiDataRequests.list.addiDataRequests;
export const selectAddiDataRequestsListLoading = (state: RootState): boolean => state.administration.addiDataRequests.list.isAddiDataRequestsLoading;

export const selectAddiDataRequestDetails = (state: RootState): AddiDataRequest | null => state.administration.addiDataRequests.details.addiDataRequest;
export const selectIsAddiDataRequestLoading = (state: RootState): boolean => state.administration.addiDataRequests.details.isAddiDataRequestLoading;
export const selectIsAddiDataRequestApproving = (state: RootState): boolean => state.administration.addiDataRequests.details.isAddiDataRequestApproving;
export const selectIsAddiDataRequestRejecting = (state: RootState): boolean => state.administration.addiDataRequests.details.isAddiDataRequestRejecting;
