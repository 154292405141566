import {
	AxisLabelsFormatterContextObject,
	ChartClickEventObject,
	Options,
	XAxisLabelsOptions,
	XAxisOptions
} from 'highcharts';

import { common } from '@material-ui/core/colors';

import {
	SELECTED_BAR_COLOR,
	UNSELECTED_BAR_COLOR,
	BAR_HOVER_COLOR,
	BAR_TEXT_COLOR,
	FOCUSED_BAR_BORDER_COLOR,
	FOCUSED_BAR_BORDER_WIDTH,
	FACET_CARD_HIGHCHARTS_X_AXIS_CROSSHAIR_CLASS
} from 'styles/constants.ui';

// import { Facet, SelectedFacetValue, FacetRangeType } from '../types';
import { Facet, SelectedFacetValue } from '../types';

import { getCountSeriesData } from './getCountSeriesData';
import { getFacetValueItemsName } from './getFacetValueItemsName';

export interface PatchedOptions extends Options {
	xAxis?: (XAxisOptions|Array<XAxisOptions>) & {
		labels?: XAxisLabelsOptions & {
			events: {
				click: (this: AxisLabelsFormatterContextObject) => void
			}
		}
	}
}

export const getHighchartsOptions = (
	facetName: string,
	facet: Facet,
	selectedFacetValues: SelectedFacetValue[],
	barHeight: number,
	barPadding: number,
	highchartFooterHeight: number,
	selectFacetValue: (facetName: string, value: SelectedFacetValue) => void,
	unselectFacetValue: (facetName: string, value: SelectedFacetValue) => void
): PatchedOptions => {
	const maxFacetCount = Math.max(...facet.values.map(({ count }) => count));
	barHeight = 26;
	return {
		title: undefined,
		chart: {
			height: facet.values.length * (barHeight + barPadding) + highchartFooterHeight,
			type: 'bar',
			style: {
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
				fontSize: '0.75rem',
				cursor: 'auto',
				visibility: 'visible', // fix hidden bars on drag and drop
			},
			events: {
				// select bar by crosshair click
				click(e) {
					const chartClickEvent = e as ChartClickEventObject;
					const pointIndex = Math.round(chartClickEvent.xAxis[0].value);

					const point = this.series[0].points[pointIndex];
					if (point) {
						point.select(!point.selected);
					}
				}
			}
		},
		plotOptions: {
			bar: {
				cursor: 'pointer',
				allowPointSelect: true,
				// accessibility: {
				// pointDescriptionFormatter(point) {
				// return `${facet.rangeType === FacetRangeType.Values || facet.rangeType === FacetRangeType.Interval ? 'Range' : 'Value'} for filtration is ${point.category}, number of matches is ${point.y || 0}`;
				// }
				// },
				accessibility: {},
				dataLabels: {
					enabled: true,
					style: {
						color: BAR_TEXT_COLOR,
						fontSize: '0.75rem',
						fontWeight: 'normal',
						textOutline: '0px'
					}
				},
				color: selectedFacetValues.length ? UNSELECTED_BAR_COLOR : SELECTED_BAR_COLOR,
				states: {
					hover: {
						brightness: 0
					},
					select: {
						color: SELECTED_BAR_COLOR
					}
				},
			},
			series: {
				point: {
					events: {
						// by default Highcharts supports multiple selection only with pressed shift key
						select(pointEvent) {
							pointEvent.preventDefault();

							const { chart, points } = this.series;

							if (points?.filter(point => point.selected).length === 0) {
								chart.update({
									plotOptions: {
										bar: {
											color: UNSELECTED_BAR_COLOR
										}
									}
								});
							}

							this.update({ selected: true }, true);
							selectFacetValue(facetName, {
								value: this.options.custom?.value as string | undefined,
								from: this.options.custom?.from as number | undefined,
								to: this.options.custom?.to as number | undefined
							});
						},
						unselect(pointEvent) {
							pointEvent.preventDefault();

							const { chart, points } = this.series;

							if (points?.filter(point => point.selected).length === 1) {
								chart.update({
									plotOptions: {
										bar: {
											color: SELECTED_BAR_COLOR
										}
									}
								});
							}

							this.update({ selected: false }, true);
							unselectFacetValue(facetName, {
								value: this.options.custom?.value as string | undefined,
								from: this.options.custom?.from as number | undefined,
								to: this.options.custom?.to as number | undefined
							});
						},
						click() {
							return undefined;
						}
					}
				}
			}
		},
		// custom tooltip element
		tooltip: {
			borderColor: UNSELECTED_BAR_COLOR,
			backgroundColor: common.white,
			pointFormat: `<div style="display: flex; flex-direction: column;">
			<div>{point.key}</div>
			<div style="color: ${SELECTED_BAR_COLOR}; font-size: 1rem">{point.y}</div>
		</div>`,
			shared: true,
			style: {
				width: 200,
				fontSize: '0.75rem'
			},
			useHTML: true
		},

		xAxis: {
			crosshair: {
				color: BAR_HOVER_COLOR,
				className: FACET_CARD_HIGHCHARTS_X_AXIS_CROSSHAIR_CLASS
			},
			accessibility: {
				enabled: true
			},
			categories: getFacetValueItemsName(facet),

			lineWidth: 0,

			labels: {
				// custom label element
				formatter() {
					return `<div style="
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					width: 80px;
					text-align: right;
					font-family: Roboto, Helvetica, Arial, sans-serif;
					font-size: 0.75rem;
					cursor: pointer"
				>${this.value}</div>`;
				},
				style: {
					color: BAR_TEXT_COLOR
				},
				useHTML: false,
				events: {
					click() {
						const point = this.chart.series[0].points[this.pos];
						point.select(!point.selected);
					}
				}
			}
		},

		yAxis: {
			min: 0,
			title: undefined,
			labels: {
				style: {
					fontSize: '0.75rem',
					color: BAR_TEXT_COLOR
				}
			},
			max: maxFacetCount || 1,
			allowDecimals: false,
			showLastLabel: maxFacetCount !== 0
		},

		legend: {
			enabled: false
		},

		series: [
			{
				accessibility: {
					keyboardNavigation: {
						enabled: true
					}
				},
				borderRadius: 0,
				borderWidth: 0,
				name: 'count',
				// pointWidth: barHeight,
				pointWidth: 24,
				pointPadding: 0.05,
				type: 'bar',
				data: getCountSeriesData(facet.rangeType, facet.values, selectedFacetValues)
			}
		],

		accessibility: {
			enabled: true,
			keyboardNavigation: {
				focusBorder: {
					style: {
						color: FOCUSED_BAR_BORDER_COLOR,
						borderRadius: 0,
						lineWidth: FOCUSED_BAR_BORDER_WIDTH
					}
				}
			}
		},

		exporting: {
			enabled: false
		}
	};
};
