import { AuthManagerTypes } from '../auth/types';

import { ApiApplicationType, ApiUrls } from './types';

const usersManagementRootUri = '/v1.0/users';

export const Urls: ApiUrls = {
	GetDefaultFacetsConfig: {
		path: '/v1.0/configuration/facets/default',
		applicationType: ApiApplicationType.Search,
	},
	GetDetailsFacetsConfig: {
		path: '/v2.0/configuration/facets/details',
		applicationType: ApiApplicationType.Search,
	},
	Search: {
		path: '/v1.0/metadata/search',
		applicationType: ApiApplicationType.Search,
		authManagerType: AuthManagerTypes.Portal
	},
	SearchPublic: {
		path: '/v1.0/metadata/search-public',
		applicationType: ApiApplicationType.Search,
	},
	GetDownloadMetadataLink: {
		path: '/v1.0/metadata/download',
		applicationType: ApiApplicationType.Search,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetCaseSetConfiguration: {
		path: '/v1.0/case-set/configuration',
		applicationType: ApiApplicationType.Search,
	},
	GetExternalCaseSet: {
		path: '/v1.0/case-set/external',
		applicationType: ApiApplicationType.Search,
	},
	ValidateCaseSet: {
		path: '/v1.0/case-set/validate',
		applicationType: ApiApplicationType.Search,
	},
	GetDefaultFieldsConfig: {
		path: '/v1.0/configuration/fields',
		applicationType: ApiApplicationType.Search,
	},
	GetEntitiesCount: {
		path: '/v1.0/metadata/entities-count',
		applicationType: ApiApplicationType.Search,
	},
	GetUserMenuItems: {
		path: '/v1.0/user-context/user-menu',
		applicationType: ApiApplicationType.Search,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetTranslations: {
		path: '/v1.0/localization/labels',
		applicationType: ApiApplicationType.Search,
	},
	GetCaseSetTemplate: {
		path: '/v1.0/case-set/template/download',
		applicationType: ApiApplicationType.Search,
	},
	GetUserRoles: {
		path: '/v1.0/user-context/me',
		applicationType: ApiApplicationType.Search,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetPluginsGroups: {
		path: '/v1.0/configuration/plugins/plugin-groups',
		applicationType: ApiApplicationType.ProceedSearch,
	},
	GetPlugins: {
		path: '/v1.0/configuration/plugins/plugins',
		applicationType: ApiApplicationType.ProceedSearch,
	},
	SearchRequests: {
		path: '/v1.0/search-requests',
		applicationType: ApiApplicationType.ProceedSearch,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetAuthenticationCode: {
		path: '/v1.0/authentication/get-authentication-code',
		applicationType: ApiApplicationType.Billing,
		authManagerType: AuthManagerTypes.Cli,
	},
	GetPossibleUserRoles: {
		path: '/v1.0/groups/list',
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetUsersList: {
		path: '/v1.0/users/list',
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	ExportUsers: {
		path: '/v1.0/users/export',
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	UpdateUser: {
		path: usersManagementRootUri,
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	ApproveUserDua: {
		path: '/v1.0/dua/approve',
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	RejectUserDua: {
		path: '/v1.0/dua/reject',
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetUser: {
		path: usersManagementRootUri,
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	DeleteUser: {
		path: usersManagementRootUri,
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetDuaConfig: {
		path: '/v1.0/config/dua',
		applicationType: ApiApplicationType.UsersManagement,
	},
	SendDuaSubmitRequest: {
		path: '/v1.0/dua',
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	SendDuaSignRequest: {
		path: '/v1.0/dua/sign-request',
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	SendResearcherSurvey: {
		path: '/v1.0/dua/researcher-survey',
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetDocuSignUserAgreement: {
		path: '/v1.0/dua/attachment/docusign',
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetPages: {
		path: '/pages',
		applicationType: ApiApplicationType.Wordpress,
	},
	GetCategories: {
		path: '/categories',
		applicationType: ApiApplicationType.Wordpress,
	},
	GetVisualizationParticipantsList: {
		path: '/v1.0/visualization/participants',
		applicationType: ApiApplicationType.ExtInt,
	},
	GetVisualizationTranscriptomicsData: {
		path: '/v1.0/visualization/data/transcriptomics',
		applicationType: ApiApplicationType.ExtInt,
	},
	DownloadHeatmapData: {
		path: '/v1.0/visualization/download/transcriptomics',
		applicationType: ApiApplicationType.ExtInt,
	},
	GetSubmitDataConfig: {
		path: '/v1.0/plugins/big-data/upload-configuration',
		applicationType: ApiApplicationType.ExtInt,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetUserDoiList: {
		path: '/v1.0/doi/list',
		applicationType: ApiApplicationType.Doi,
		authManagerType: AuthManagerTypes.Portal,
	},
	CreateUserDoi: {
		path: '/v1.0/doi',
		applicationType: ApiApplicationType.Doi,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetUserOmics: {
		path: '/v1.0/doi/omics',
		applicationType: ApiApplicationType.Doi,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetUserDoi: {
		path: '/v1.0/doi',
		applicationType: ApiApplicationType.Doi,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetDoiConfig: {
		path: '/v1.0/doi/config',
		applicationType: ApiApplicationType.Doi,
	},
	DownloadCreateFileList: {
		path: '/v1.0/doi/omics/download',
		applicationType: ApiApplicationType.Doi,
		authManagerType: AuthManagerTypes.Portal,
	},
	DownloadReadFileList: {
		path: '/v1.0/doi/omics/download',
		applicationType: ApiApplicationType.Doi,
	},
	GetIndexList: {
		path: '/v1.0/index/list',
		applicationType: ApiApplicationType.IndexManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetCurrentIndex: {
		path: '/v1.0/index/default',
		applicationType: ApiApplicationType.IndexManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	ExportReleasedFilesList: {
		path: '/v1.0/release-data/files',
		applicationType: ApiApplicationType.IndexManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	ExportParticipantsIntersectionList: {
		path: '/v1.0/release-data/participants',
		applicationType: ApiApplicationType.IndexManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	ExportUploadedFilesList: {
		path: '/v1.0/release-data/uploads',
		applicationType: ApiApplicationType.IndexManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	SendCreateManagementRequest: {
		path: '/v1.0/index',
		applicationType: ApiApplicationType.IndexManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetOmicsReleaseReady: {
		path: '/v1.0/release-process/ready',
		applicationType: ApiApplicationType.IndexManagement,
		authManagerType: AuthManagerTypes.Portal
	},
	ExportOmicsReleaseReady: {
		path: '/v1.0/release-process/ready/export',
		applicationType: ApiApplicationType.IndexManagement,
		authManagerType: AuthManagerTypes.Portal
	},
	StartReleaseProcess: {
		path: '/v1.0/release-process/start',
		applicationType: ApiApplicationType.IndexManagement,
		authManagerType: AuthManagerTypes.Portal
	},
	GetReleaseOperations: {
		path: '/v1.0/release-process/operations',
		applicationType: ApiApplicationType.IndexManagement,
		authManagerType: AuthManagerTypes.Portal
	},
	GetTotalNumberOfUsers: {
		path: '/v1.0/statistics/users/cumulative',
		applicationType: ApiApplicationType.Statistics,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetTotalNumberOfNewUsers: {
		path: '/v1.0/statistics/users/growth',
		applicationType: ApiApplicationType.Statistics,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetMetadataDownloads: {
		path: '/v1.0/statistics/downloads/metadata',
		applicationType: ApiApplicationType.Statistics,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetTotalAmountOfDownloadedData: {
		path: '/v1.0/statistics/downloads/omics',
		applicationType: ApiApplicationType.Statistics,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetStepsToDownload: {
		path: '/v1.0/statistics/users/download-steps-funnel',
		applicationType: ApiApplicationType.Statistics,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetOmicsYearlyDownloads: {
		path: '/v1.0/statistics/downloads/omics-yearly',
		applicationType: ApiApplicationType.Statistics,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetAddiDataRequestsList: {
		path: '/v1.0/addi/list',
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal,
	},
	GetAddiDataRequestDetails: {
		path: '/v1.0/addi',
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal
	},
	ApproveAddiDataRequest: {
		path: '/v1.0/addi/approve',
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal
	},
	RejectAddiDataRequest: {
		path: '/v1.0/addi/reject',
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal
	},
	SendAddiSignRequest: {
		path: '/v1.0/addi/sign-request',
		applicationType: ApiApplicationType.UsersManagement,
		authManagerType: AuthManagerTypes.Portal
	}
};
