import React, { FunctionComponent, useCallback } from 'react';

import {
	BoxProps,
	Checkbox,
	createStyles,
	List,
	ListItem,
	ListItemIcon,
	Paper,
	Theme
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

interface OmicCardLevel {
	checkboxValue: boolean
	formFieldName: string
	label: string
	helperText: string
}

interface OmicCardProps {
	cardName: string
	levels: OmicCardLevel[]
	onLevelClick: (formFieldName: string) => void
	wrapperBoxProps?: BoxProps
	isReadonly?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItemDense: {
			padding: 0
		},
		listItemIconRoot: {
			minWidth: 0,
			paddingRight: theme.spacing(1)
		}
	})
);

export const OmicCard: FunctionComponent<OmicCardProps> = ({
	cardName,
	levels,
	onLevelClick,
	wrapperBoxProps = {},
	isReadonly = false
}: OmicCardProps) => {
	const classes = useStyles();

	const onItemClick = useCallback((formFieldName: string) => () => {
		onLevelClick(formFieldName);
	}, [onLevelClick]);

	return (
		<Box {...wrapperBoxProps}>
			<Paper>
				<Box p={2}>
					<Box mb={2}>
						<Typography
							variant="h5"
							component="h4"
						>
							{ cardName }
						</Typography>
					</Box>
					<List>
						{levels.map((levelInfo, levelIndex) =>
							<Box
								key={ levelIndex }
								component="li"
							>
								<ListItem
									role={ undefined }
									dense
									divider={ levelIndex !== levels.length - 1 }
									button
									disableRipple
									classes={{ dense: classes.listItemDense }}
									onClick={ onItemClick(levelInfo.formFieldName) }
									disabled={ isReadonly }
								>
									<ListItemIcon classes={{ root: classes.listItemIconRoot }}>
										<Checkbox
											name={levelInfo.formFieldName}
											color="primary"
											checked={levelInfo.checkboxValue}
											tabIndex={-1}
											disableRipple
											inputProps={{
												'aria-label': `${cardName} ${levelInfo.label}`
											}}
										/>
									</ListItemIcon>
									<Box display="flex" justifyContent="space-between" alignItems="center" color={grey['800']} width="100%">
										<Box pr={2}>
											<Typography variant="body1" component="p">
												{ levelInfo.label }
											</Typography>
										</Box>
										<Box pr={1}>
											<Typography variant="body2" component="p">
												{ levelInfo.helperText }
											</Typography>
										</Box>
									</Box>
								</ListItem>
							</Box>
						)}
					</List>
				</Box>
			</Paper>
		</Box>
	);
};
