import { SagaIterator } from 'redux-saga';

import { addiDataRequestDetailsSlice, addiDataRequestsListSlice } from '../slices';
import { takeEvery } from '@redux-saga/core/effects';
import { getAddiDataRequestsListSaga } from './getAddiDataRequestsListSaga';
import { getAddiDataRequestDetailsFlowSaga } from './getAddiDataRequestDetailsFlowSaga';
import { fork } from 'redux-saga/effects';
import { approveAddiDataRequestSaga } from './approveAddiDataRequestSaga';
import { rejectAddiDataRequestSaga } from './rejectAddiDataRequestSaga';
import { sendAddiSignDataRequestSaga } from './sendAddiSignDataRequestSaga';

export function* rootAddiDataRequestsSaga(): SagaIterator {
	yield fork(getAddiDataRequestDetailsFlowSaga);
	yield takeEvery(addiDataRequestsListSlice.actions.getAddiDataRequestsList.type, getAddiDataRequestsListSaga);
	yield takeEvery(addiDataRequestDetailsSlice.actions.approveAddiDataRequest.type, approveAddiDataRequestSaga);
	yield takeEvery(addiDataRequestDetailsSlice.actions.rejectAddiDataRequest.type, rejectAddiDataRequestSaga);
	yield takeEvery(addiDataRequestDetailsSlice.actions.sendAddiSignDataRequest.type, sendAddiSignDataRequestSaga);
}
