import React, { FunctionComponent } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

interface SkeletonWrapperProps {
	width: number
	height: number
}

export const SkeletonWrapper: FunctionComponent<SkeletonWrapperProps> = ({
	width,
	height
}: SkeletonWrapperProps) =>
	<Skeleton
		width={width}
		height={height}
		animation="pulse"
		variant="text"
	/>
;
